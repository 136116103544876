<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        persistent
        max-width="700"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="wrapperFormModal">
                <v-card
                    flat
                >
                    <v-card-text>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="3">
                                <label class="f-label">{{ $t("Профайл") }}</label>
                            </v-col>

                            <v-col cols="12" sm="12" md="9">
                                <v-text-field
                                    :value="container"
                                    @input="$store.commit('emsDialogs/edsSettings/SET_CONTAINER', $event)"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row no-gutters>
                            <v-col cols="12" sm="12" md="3">
                                <label class="f-label">{{ $t("Уникальное_имя") }}</label>
                            </v-col>

                            <v-col cols="12" sm="12" md="9">
                                <v-text-field
                                    :value="dn"
                                    @input="$store.commit('emsDialogs/edsSettings/SET_DN', $event)"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        
                    </v-card-text>

                </v-card>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="ok"
                    v-if="container && dn"
                >
                    {{ $t("Сохранить") }}
                </v-btn>

                <v-btn 
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>
            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "EDSSettingsDlg",
    data: () => ({
        title: "Настройки_ЭЦП",
    }),
    computed: {
        ...mapGetters('emsDialogs/edsSettings',
        {
            visible: 'isVisible',
            container: 'getContainer',
            dn: 'getDN',
        }),
    },
    methods: {
        ...mapActions('emsDialogs/edsSettings', [ 'ok', 'cancel' ]),
    }
}
</script>