<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{this.title}}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">

                    <v-row no-gutters>
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{ $t("Логин") }}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <v-text-field
                                v-model="login"
                                hide-details
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters v-if="isInner">
                        <v-col cols="12" sm="12" md="4">
                            <label class="f-label">{{ $t("Уровень_доступа") }}</label>
                        </v-col>
                        <v-col cols="12" sm="12" md="8">
                            <div class="icon-click-row-group icrg-align-center">
                                <v-autocomplete
                                    v-model="level"
                                    :items="GetSecurityLevelAccessForSelect"
                                    item-text="Value"
                                    item-value="id"
                                    return-object
                                    hide-details
                                    outlined
                                    dense
                                    append-icon="fas fa-chevron-down"
                                >
                                </v-autocomplete>
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn 
                        color="cyan" 
                        text
                        depressed 
                        v-if="login && (!isInner || (level && level.id > 0))"
                        @click="ok" 
                    >
                        {{$t("Создать")}}
                    </v-btn>

                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{$t("Отмена")}}
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import i18n from '@/i18n'
import { mapGetters } from 'vuex';

export default {
    name: "CreateUserDlg",
    data () {
        return {
            title: i18n.t("Создание_пользователя"),
            visible: false,
            resolve: null,
            reject: null,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            login: '',
            level: 0,
            isInner: false
        }
    },
    computed: {
        ...mapGetters('references', ['GetSecurityLevelAccess']),
        ...mapGetters('auth', ['getUserInfo']),
        GetSecurityLevelAccessForSelect(){
            return this.GetSecurityLevelAccess.filter(item=>item.id>0 && item.id<100);
        },
    },
    methods: {
        async open(isInnerEmployee, options = {})
        {
            this.reset();
            this.visible = true;
            this.options = Object.assign(this.options, options);
            this.isInner = isInnerEmployee;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        cancel() {
            this.visible = false;
            this.reject({
                isCancelled: true,
                message: "Действие отменено"
            });
        },
        ok() {
            this.visible = false;
            this.resolve({ param: this.getResultObject() });
        },
        getResultObject() {
            return { login: this.login, level: this.isInner ? this.level : 0 };
        },
        reset() {
            this.login = '';
            this.level = null;
        },

    }
}
</script>