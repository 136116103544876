<template>
    <div class="resol-tree">
        <template v-if="items == null || items.length === 0">
            <div class="div-table-row dtr-no-data">
                <div class="cfl-placeholder">{{ $t("К_сожалению,_по_Вашему_запросу_ничего_не_найдено") }}</div>
            </div>
        </template>
        <template
            v-else
        >
            <SecurityRuleItem
                v-for="item in items"
                :key="item.id"
                :item="item"
                :values="values"
                :viewMode="viewMode"
                :open-all="openAll"
                :role="role"
                :active-id="activeId"
                :on-item-click="onTreeItemClick"
                :on-item-dbl-click="onTreeItemDblClick"
                :on-invoke-action="onInvokeAction"
            />
        </template>
    </div>
</template>

<script>
import SecurityRuleItem from '@/components/ems/SecurityRuleItem.vue'

export default {
    name: "SecurityRulesTreeView",
    components: {
        SecurityRuleItem
    },
    props: {
        items: Array,
        values: Array,
        openAll: Boolean,
        viewMode: Boolean,
        role: Boolean
    },
    data() {
        return {
            activeId: null,
            activeItem: null
        }
    },
    async created() {
        //console.log(this.values);
    },
    methods: {
        onTreeItemClick(item) {
            if (this.activeId !== item.id) {
                this.activeId = item.id;
                this.activeItem = item;
                this.$emit('on-selected-change', item);
            }

            this.$emit('on-item-click', item);
        },
        onTreeItemDblClick(item) {
            this.$emit('on-item-dbl-click', item);
        },
        onInvokeAction(action, item) {
            this.$emit('on-invoke-action', action, item);
        }
    },
    watch: {
        items() {
            this.activeId = null;
            this.activeItem = null;
            this.$emit('on-selected-change', null);
        }
    }
}
</script>